import React from 'react';
import { Flex } from '../../../../../../../../utils/layout';
import styles from './resultHeaderTable.module.css';

type Props = { isStarted: boolean; title: string };
const ResultHeaderTable: React.FC<Props> = ({ isStarted, title }) => {
  return (
    <>
      <p className={styles.fixtureTitle}>{title}</p>

      <Flex justifyContent={'center'} className={styles.containerHeader}>
        <p className={styles.puesto}>PUESTO</p>
        <p className={styles.puestoMob}>Pos.</p>

        <p className={styles.ticket}>TICKET</p>
        <p className={styles.ticketMob}>N°T.</p>

        <p className={styles.user}>USUARIO</p>
        <p className={styles.userMob}>Usuario</p>

        {isStarted ? (
          <>
            <p className={styles.points}>PUNTOS</p>
            <p className={styles.pointsMob}>Pts.</p>
          </>
        ) : null}
        <span className={styles.showTicket} />
      </Flex>
    </>
  );
};

export default ResultHeaderTable;
