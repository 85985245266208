import { useQuery } from '@tanstack/react-query';
import { TournamentWithFixtureName } from '../../../../types/tournament.types';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';

type GetTournamentQueryParams = {
  includeRounds?: boolean;
  tournamentId?: number;
  from?: string;
};

const getTournament = async (
  params: GetTournamentQueryParams,
): Promise<TournamentWithFixtureName> => {
  const { includeRounds, tournamentId } = params;
  let queryString = '';
  if (includeRounds) queryString += `includeRounds=${includeRounds}&`;
  // Eliminar el último "&" si existe
  queryString = queryString.replace(/&$/, '');

  const response: TournamentWithFixtureName = await axiosFetch(
    `/tournament/id/${tournamentId || ''}?${queryString}`,
  );
  return response;
};

export default function useGetTournament(params: GetTournamentQueryParams) {
  return useQuery({
    queryKey: [
      `tournament-${params?.tournamentId || 'last'}${params.includeRounds ? '-rounds' : ''}`,
    ],
    queryFn: () => getTournament(params),
  });
}
