import { Button, Checkbox, Modal } from 'antd';
import React, { useState } from 'react';
import { Column, Flex } from '../../../utils/layout';
import styles from './downloadAppModal.module.css';
import LogoPelota from '../../../assets/logoPelota.png';

interface DownloadAppModal {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const DownloadAppModal: React.FC<DownloadAppModal> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [checked, setChecked] = useState(false);

  const handleButton = () => {
    if (checked) {
      localStorage.setItem('noShowDownloadModal', JSON.stringify(true));
    }
    onConfirm && onConfirm();
  };

  const handleCancel = () => {
    if (checked) {
      localStorage.setItem('noShowDownloadModal', JSON.stringify(true));
    }
    onClose && onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      footer={null}
      className={styles.modal}
      closeIcon={<div className={styles.closeIcon}>X</div>}
    >
      <Column className={styles.content}>
        <Flex className={styles.header}>
          <img
            src={LogoPelota}
            alt='Logo'
            width={30}
            height='auto'
            className={styles.logo}
          />
          <p className={styles.title}>Instalar Fixtuwin</p>
          <img
            src={LogoPelota}
            alt='Logo'
            width={30}
            height='auto'
            className={styles.logo}
          />
        </Flex>
        <p className={styles.text}>
          Instale la aplicación en su dispositivo para acceder fácilmente a ella
          en cualquier momento, sin tienda de aplicaciones, sin descarga, sin
          complicaciones.
        </p>
        <Flex className={styles.checkbox} onClick={() => setChecked(!checked)}>
          <Checkbox checked={checked} />

          <p>No volver a mostrar</p>
        </Flex>
        <Button type='primary' onClick={handleButton}>
          Instalar Fixtuwin
        </Button>
        <div className={styles.close} onClick={handleCancel}>
          Cerrar
        </div>
      </Column>
    </Modal>
  );
};

export default DownloadAppModal;
