import { Modal } from 'antd';
import React from 'react';
import { capitalizeFirstLetter } from '../../../utils';
import { Column } from '../../../utils/layout';
import useClientStore from '../../clientStore/useClientStore';
import { useGetTournamentNames } from '../../serverStore/queries';
import ItemList from './components/itemList/ItemList';
import styles from './homeModal.module.css';

interface IModalProps {
  open?: boolean;
  onClose?: () => void;
}

const TournamentsListModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const { data: tournamentsData } = useGetTournamentNames();
  const {
    loggedUser,
    dispatch,
    fixtureSelected: { tournamentId },
  } = useClientStore();

  const onSelectedItem = (id: number) => {
    dispatch({ type: 'SET_TOURNAMENT_ID', payload: id });
  };
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
      closeIcon={<div className={styles.closeIcon}>X</div>}
    >
      <Column className={styles.content}>
        <p className={styles.modalTitle}>Lista de Torneos</p>
        {tournamentsData?.length
          ? tournamentsData.map((t) => {
              if (
                t.id === 2 &&
                loggedUser?.email !== 'guillermoambroggio@gmail.com'
              ) {
                return null;
              }
              return (
                <ItemList
                  key={t.id}
                  status={t.status}
                  name={capitalizeFirstLetter(t.name) || t.name}
                  onSelected={() => onSelectedItem(t.id)}
                  isSelected={tournamentId === t.id}
                />
              );
            })
          : null}
      </Column>
    </Modal>
  );
};

export default TournamentsListModal;
