import React from 'react';

import styles from './predictionResultItem.module.css';
import formatDateTime from '../../../../../../../utils/hooks/formatDate';
import { Column, Flex } from '../../../../../../../utils/layout';
import { statusMatch } from '../../../../../../../types/match.types';
import { MatchesWithPredictions } from '../../PredictionResult';
import { getMatchResult } from '../../../../../../../utils';

interface ImatchItemProps {
  matchWhitPrediction?: MatchesWithPredictions;
}

const PredictionResultItem: React.FC<ImatchItemProps> = ({
  matchWhitPrediction,
}) => {
  const { predictionLocalTeam, predictionVisitingTeam, resultPoints } =
    matchWhitPrediction?.prediction || {};
  const {
    localTeam,
    visitingTeam,
    resultLocalTeam,
    resultVisitingTeam,
    status,
  } = matchWhitPrediction || {};
  const { date, time } = formatDateTime(
    new Date(matchWhitPrediction?.startDate || ''),
  );

  if (!localTeam || !visitingTeam) return null;

  const { isExactResult, isRightResult } = getMatchResult({
    predictionLocalTeam,
    predictionVisitingTeam,
    resultLocalTeam,
    resultVisitingTeam,
  });

  return (
    <Flex className={styles.containerMatchItem}>
      <Flex className={styles.matchItemWithResult}>
        <div className={styles.containerFixtureItem}>
          {/* TEAM LEFT */}
          <div className={`${styles.containerTeam}`}>
            <div>
              <img
                src={localTeam?.image}
                alt={`escudo-${localTeam.name}`}
                className={styles.imgTeam}
              />
            </div>
            <Column className={styles.teamNameWithResult}>
              <p className={styles.teamText}>{localTeam.name}</p>
              <span className={styles.predictionNumber}>
                ({predictionLocalTeam != null ? predictionLocalTeam : '-'})
              </span>
            </Column>
          </div>

          {/* RESULT CENTER */}

          <Flex
            className={`${styles.containerResult} ${isExactResult ? styles.exactResult : ''} ${isRightResult ? styles.rightResult : ''}`}
          >
            <span>{resultLocalTeam != null ? resultLocalTeam : ''}</span>
            <p>-</p>
            <span>{resultVisitingTeam != null ? resultVisitingTeam : ''}</span>
          </Flex>

          {status ? (
            <Column className={styles.containerStatusMobile}>
              {status === 'toStart' ? (
                <p>
                  {date}-{time}
                </p>
              ) : (
                <p>
                  {statusMatch[status]}-{date}
                </p>
              )}
            </Column>
          ) : null}

          {/* TEAM RIGHT */}
          <div
            className={`${styles.containerTeam}  ${styles.containerTeamRight}`}
          >
            <Column
              className={`${styles.teamNameWithResult} ${styles.teamNameWithResultRight}`}
            >
              <p className={styles.teamTextRight}>{visitingTeam.name}</p>
              <span className={styles.predictionNumberRight}>
                ({predictionVisitingTeam != null ? predictionVisitingTeam : '-'}
                )
              </span>
            </Column>
            <div>
              <img
                src={visitingTeam.image}
                alt={`escudo-${visitingTeam.name}`}
                className={styles.imgTeam}
              />
            </div>
          </div>
        </div>
        <Column className={styles.containerPoints}>
          {resultPoints != null ? resultPoints : '-'}
        </Column>
      </Flex>
      {status ? (
        <Column className={styles.containerStatus}>
          {status === 'toStart' ? (
            <>
              <p className={styles.statusText}>{date}</p>
              <p className={styles.statusText}>{time}</p>
            </>
          ) : (
            <>
              <p className={styles.statusText}>{statusMatch[status]}</p>
              <p className={styles.statusText}>{date}</p>
            </>
          )}
        </Column>
      ) : null}
    </Flex>
  );
};

export default PredictionResultItem;
