import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import useWriting from '../../../../copywriting/useWriting';
import { IAuth, UserAttributes } from '../../../../types/user.types';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import useClientStore from '../../../clientStore/useClientStore';
import { useWhoami } from '../../queries/user/useGetUser';
import useLogout from './useLogout';

type IPostRegister = (body: UserAttributes) => Promise<IAuth>;
const postRegister: IPostRegister = async (body) => {
  const data = await axiosFetch('/user/create-user', {
    method: 'POST',
    data: body,
  });
  return data;
};

const useRegister: () => UseMutationResult<
  IAuth,
  any,
  UserAttributes,
  unknown
> = () => {
  const { dispatch } = useClientStore();
  const { mutate } = useLogout();
  const writing = useWriting();
  const serverStore = useQueryClient();
  return useMutation({
    mutationFn: postRegister,
    onSuccess: async (data) => {
      if (!data) return mutate();
      const user = await useWhoami(data?.csrfToken);
      serverStore.invalidateQueries({ queryKey: ['trusted-user'] });
      dispatch({ type: 'AUTH', payload: data });
      dispatch({ type: 'SET_USER', payload: user });
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'success',
          message: writing.user.register.successMessage,
        },
      });
    },
    onError: () => {
      mutate();
    },
  });
};

export default useRegister;
