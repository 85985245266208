import React, { useState } from 'react';
import { Column, Flex } from '../../../../../../../../utils/layout';
import styles from './resultItemTable.module.css';
import useClientStore from '../../../../../../../clientStore/useClientStore';
import { CustomTooltip } from '../../../../../../../commons';
import { GenericTickets } from '../../../../../../../../types/ticket.types';
import ShowPredictionsModal, {
  TicketName,
} from '../../../../../../../modals/showPredictionsModal/ShowPredictionsModal';

interface IResultItemTableProps {
  ticket: GenericTickets;
  isStarted?: boolean;
  position: number;
  isFirstPosition?: boolean;
  isSecondPosition?: boolean;
  isThirdPosition?: boolean;
  ticketName: TicketName;
}

const ResultItemTable: React.FC<IResultItemTableProps> = ({
  ticket,
  position,
  isStarted,
  isFirstPosition,
  isSecondPosition,
  isThirdPosition,
  ticketName,
}) => {
  const { loggedUser } = useClientStore();
  const [openShowPredictions, setOpenShowPredictions] = useState(false);

  const isTop = isFirstPosition || isSecondPosition || isThirdPosition;

  const enabledShow =
    loggedUser &&
    (loggedUser?.userType === 'administrator' ||
      isStarted ||
      loggedUser?.id === ticket.user?.id);

  const titleTooltip = !loggedUser
    ? 'Inicia sesion para ver'
    : 'La fecha todavia no comenzó';

  return (
    <Flex style={{ gap: 4 }} className={styles.container}>
      <Flex
        className={`${styles.puesto} ${
          isFirstPosition ? styles.firstPosition : ''
        } ${isSecondPosition ? styles.secondPosition : ''} ${
          isThirdPosition ? styles.thirdPosition : ''
        }`}
      >
        {position}
        {isTop ? (
          <i
            className={`fas fa-medal ${isFirstPosition ? styles.gold : ''} ${
              isSecondPosition ? styles.silver : ''
            }  ${isThirdPosition ? styles.bronze : ''}`}
          />
        ) : null}
      </Flex>
      <p
        className={`${styles.ticket} ${
          isFirstPosition ? styles.firstPosition : ''
        } ${isSecondPosition ? styles.secondPosition : ''}  ${
          isThirdPosition ? styles.thirdPosition : ''
        }`}
      >
        {`#${ticket.id}`}
      </p>
      <Column
        className={`${styles.user} ${
          isFirstPosition ? styles.firstPosition : ''
        } ${isSecondPosition ? styles.secondPosition : ''}  ${
          isThirdPosition ? styles.thirdPosition : ''
        }`}
      >
        <p
          className={styles.textName}
        >{`${ticket.user?.name} ${ticket.user?.lastname}`}</p>
      </Column>
      {/* COLUMNA PARA VER LOS PUNTOS DEL TICKET */}
      {isStarted ? (
        <Column
          className={`${styles.points} ${
            isFirstPosition ? styles.firstPosition : ''
          } ${isSecondPosition ? styles.secondPosition : ''}  ${
            isThirdPosition ? styles.thirdPosition : ''
          }`}
        >
          {ticket.resultPoints || '0'}
        </Column>
      ) : null}
      {/* COLUMNA PARA MOSTRAR EL TICKET */}

      <CustomTooltip title={titleTooltip} disabled={enabledShow}>
        <div
          className={`${styles.showTicket} ${
            !enabledShow ? styles.showTicketDisabled : null
          }`}
          onClick={() => (enabledShow ? setOpenShowPredictions(true) : '')}
        >
          <i className='far fa-eye'></i>
          <p className={styles.alertText}>Ver</p>
        </div>
      </CustomTooltip>

      {openShowPredictions && ticket.user?.email ? (
        <ShowPredictionsModal
          open={openShowPredictions}
          onClose={() => setOpenShowPredictions(false)}
          ticketName={ticketName}
          email={ticket.user?.email}
        />
      ) : null}
    </Flex>
  );
};

export default ResultItemTable;
