import React, { useState } from 'react';
import { FixtureAttributes } from '../../../../../../../types/fixture.types';
import { capitalizeFirstLetter } from '../../../../../../../utils';
import useClientStore from '../../../../../../clientStore/useClientStore';
import { CustomTooltip } from '../../../../../../commons';
import { FixtureListModal } from '../../../../../../modals';
import { useGetFixtureNames } from '../../../../../../serverStore/queries';

import styles from './fixtureName.module.css';

interface IFixtureNameProps {
  fixture?: FixtureAttributes;
  refFixtureChangeName: React.MutableRefObject<null>;
}

const FixtureName: React.FC<IFixtureNameProps> = ({
  fixture,
  refFixtureChangeName,
}) => {
  const [openFixturesListModal, setOpenFixturesListModal] = useState(false);

  const {
    dispatch,
    fixtureSelected: { tournamentId },
  } = useClientStore();
  const { data: fixtureNames } = useGetFixtureNames(tournamentId);

  /* Fixture names esta ordenado de tal manera que los fixtures que estan por comenzar estan primeros en el array */
  const fixturePagination = fixtureNames?.reduce(
    (acc, f, i) => {
      if (f.id === fixture?.id) {
        /* si tiene otro fixture antes que el actual entonces puede ir al siguiente (por el orden decreciente de los fixtures names) */
        if (fixtureNames[i - 1]) {
          acc.isNext = true;
          acc.nextId = fixtureNames[i - 1].id;
        }
        /* si tiene otro fixture despues que el actual entonces puede ir al anterior (por el orden decreciente de los fixtures names) */
        if (fixtureNames[i + 1]) {
          acc.isPrev = true;
          acc.prevId = fixtureNames[i + 1].id;
        }
      }
      return acc;
    },
    { isPrev: false, isNext: false, prevId: 0, nextId: 0 },
  );

  const handleOpenFixtureList = () => {
    setOpenFixturesListModal(true);
  };

  return (
    <>
      <div className={styles.fixtureContainer}>
        <div className={styles.iconFixture}>
          {fixturePagination?.isPrev ? (
            <i
              className='fas fa-angle-left'
              onClick={() =>
                dispatch({
                  type: 'SET_FIXTURE_ID',
                  payload: fixturePagination.prevId,
                })
              }
            ></i>
          ) : null}
        </div>
        <CustomTooltip title='Presiona para cambiar de fixture'>
          <p
            ref={refFixtureChangeName}
            className={styles.fixturesText}
            onClick={handleOpenFixtureList}
          >
            {capitalizeFirstLetter(fixture?.name)}
          </p>
        </CustomTooltip>
        <div className={styles.iconFixture}>
          {fixturePagination?.isNext ? (
            <i
              className='fas fa-angle-right'
              onClick={() =>
                dispatch({
                  type: 'SET_FIXTURE_ID',
                  payload: fixturePagination.nextId,
                })
              }
            ></i>
          ) : null}
        </div>
      </div>

      {openFixturesListModal ? (
        <FixtureListModal
          open={openFixturesListModal}
          onClose={() => setOpenFixturesListModal(false)}
        />
      ) : null}
    </>
  );
};

export default FixtureName;
