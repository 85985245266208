import { Modal } from 'antd';
import React from 'react';
import { StatusFixture } from '../../../types/fixture.types';
import { Column } from '../../../utils/layout';
import useClientStore from '../../clientStore/useClientStore';
import { useGetFixtureNames } from '../../serverStore/queries';

import ItemList from './components/itemList/ItemList';
import styles from './homeModal.module.css';

export type FixtureListResponse = {
  name: string;
  id: number;
  status: StatusFixture;
};

interface IModalProps {
  open?: boolean;
  onClose?: () => void;
}

const FixtureListModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const {
    dispatch,
    fixtureSelected: { tournamentId, fixtureId },
  } = useClientStore();
  const { data: fixtureNames } = useGetFixtureNames(tournamentId);
  const onSelectedItem = (id: number) => {
    dispatch({ type: 'SET_FIXTURE_ID', payload: id });
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      className={styles.fixtureModal}
      closeIcon={<div className={styles.closeIcon}>X</div>}
    >
      <Column className={styles.content}>
        <p className={styles.modalTitle}>Lista de Fixtures</p>
        {fixtureNames?.map((f) => (
          <ItemList
            key={f.id}
            status={f.status}
            name={f.name}
            onSelected={() => onSelectedItem(f.id)}
            isSelected={fixtureId === f.id}
          />
        ))}
        {!fixtureNames?.length ? (
          <p className={styles.emptyText}>No se encontraron fixtures..</p>
        ) : null}
      </Column>
    </Modal>
  );
};

export default FixtureListModal;
