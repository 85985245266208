import React, { useEffect, useState } from 'react';
import './utils/axiosConfig/axiosConfig';
import { useCachedResources } from './utils';
import useClientStore from './components/clientStore/useClientStore';
import { MainRoutes } from './components/routes';
import 'antd/dist/reset.css';
import { SpinerScreen } from './components/commons';
import { notification as antdNotification } from 'antd';
import { AdsModal, DownloadAppModal } from './components/modals';
import ads from './utils/winners/ads';

const App: React.FC = () => {
  const [api, contextHolder] = antdNotification.useNotification();
  const { notification } = useClientStore();
  const isLoadingComplete = useCachedResources();
  const [modalDownloadApp, setModalDownloadApp] = useState<{
    open: boolean;
    deferredPrompt?: any;
  }>({ open: false, deferredPrompt: null });
  const [isCancelInstalled, setIsCancelInstalled] = useState(false);
  const [showAds, setShowAds] = useState(false);
  const lastAdsId = ads.id;

  const handleCloseModalDownload = () => {
    setModalDownloadApp((old) => ({ ...old, open: false }));
  };

  const handleInstall = async () => {
    setIsCancelInstalled(true);
    modalDownloadApp.deferredPrompt?.prompt();
    setModalDownloadApp((old) => ({ ...old, open: false }));
  };
  useEffect(() => {
    const storageShowDownloadModal = localStorage.getItem(
      'noShowDownloadModal',
    );
    if (!isCancelInstalled && !storageShowDownloadModal) {
      const handleBeforeInstallPrompt = (e: Event) => {
        e.preventDefault();
        setModalDownloadApp((old) => ({
          ...old,
          open: true,
          deferredPrompt: e,
        }));
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          'beforeinstallprompt',
          handleBeforeInstallPrompt,
        );
      };
    }
  }, [isCancelInstalled]);

  useEffect(() => {
    if (notification) {
      api[notification.status]({
        message: notification.message,
        description: notification.description,
        placement: 'top',
      });
    }
  }, [notification]);

  useEffect(() => {
    const lastAdsIdShowed = localStorage.getItem('ads');
    const today = new Date();
    const expiredDate = new Date(ads?.expiredDate);
    const isNotExpiredDate = today < expiredDate;

    if (
      (!lastAdsIdShowed ||
        (lastAdsIdShowed && Number(lastAdsIdShowed) < lastAdsId)) &&
      isNotExpiredDate
    ) {
      setShowAds(true);
    }
  }, [ads]);

  if (!isLoadingComplete) {
    return <SpinerScreen />;
  }
  return (
    <>
      {contextHolder}
      <MainRoutes />
      {
        <DownloadAppModal
          onClose={handleCloseModalDownload}
          onConfirm={handleInstall}
          open={modalDownloadApp.open}
        />
      }
      {showAds ? (
        <AdsModal
          onClose={() => setShowAds(false)}
          open={showAds}
          adsId={lastAdsId}
        />
      ) : null}
    </>
  );
};

export default App;
