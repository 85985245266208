import { useQuery } from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import useClientStore from '../../../clientStore/useClientStore';

const getSavePredictionSecret = async (): Promise<string> => {
  const response: string = await axiosFetch('/prediction/save');
  return response;
};

export default function useGetSavePredictionSecret() {
  const { loggedUser } = useClientStore();
  return useQuery({
    queryKey: ['save-prediction'],
    queryFn: () => getSavePredictionSecret(),
    enabled: !!loggedUser,
  });
}
