// Configuración del tema
const theme = {
  token: {
    // Seed Token
    colorPrimary: '#f9ba15',
    borderRadius: 16,
    colorSuccess: '#52c41a',
    colorWarning: '#ffc400',
    colorError: '#f5222d',

    colorLinkHover: '#f9ba15',
    colorLink: '#ffff',
    colorPrimaryHover: '#f3ae00',
    colorTextLightSolid: '#1b1b1b',
    // Alias Token
    colorBgContainer: 'white',
  },
  components: {
    Upload: {
      /* here is your component tokens */
    },
    Select: {
      /* here is your component tokens */
      optionActiveBg: '#585858',
      optionSelectedBg: '#f9ba15',
      selectorBg: '#1b1b1b',
      optionSelectedColor: '#ffff',
      optionSelectedFontWeight: 800,
    },
  },
};

// Exporta el tema
export default theme;
