import { SimplePrediction } from '../../components/screens/home/components/fixtureSection/FixtureSection';

const filterNullPredictions = (predictions: SimplePrediction[]) => {
  const filterPredictions = predictions?.filter(
    (p) =>
      p?.predictionLocalTeam != undefined &&
      p?.predictionVisitingTeam != undefined,
  );

  return filterPredictions;
};

export default filterNullPredictions;
