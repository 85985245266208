import React, { useState } from 'react';
import CupIcon from '../../../../../../../assets/CupIcon';
import { TournamentWithFixtureName } from '../../../../../../../types/tournament.types';
import { CustomTooltip } from '../../../../../../commons';
import { TournamentsListModal } from '../../../../../../modals';
import styles from './tournamentName.module.css';

interface ITournamentNameProps {
  refTournamentChangeName: React.MutableRefObject<null>;
  tournament?: TournamentWithFixtureName;
}

const TournamentName: React.FC<ITournamentNameProps> = ({
  refTournamentChangeName,
  tournament,
}) => {
  const [openTournamentsListModal, setOpenTournamentsListModal] =
    useState(false);
  return (
    <>
      <CustomTooltip title='Presiona para cambiar de torneo'>
        <div
          ref={refTournamentChangeName}
          className={styles.tournamentText}
          onClick={() => {
            setOpenTournamentsListModal(true);
          }}
        >
          <CupIcon />
          <p className={styles.tournamentName}>
            {tournament ? tournament.name?.toUpperCase() : 'Seleccionar Torneo'}
          </p>
          <CupIcon />
        </div>
      </CustomTooltip>
      {openTournamentsListModal ? (
        <TournamentsListModal
          open={openTournamentsListModal}
          onClose={() => setOpenTournamentsListModal(false)}
        />
      ) : null}
    </>
  );
};

export default TournamentName;
