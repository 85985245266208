import { useEffect, useRef, useState } from 'react';
import useClientStore from '../../components/clientStore/useClientStore';
import { useWhoami } from '../../components/serverStore/queries/user/useGetUser';
import logger from '../logger/logger';
import generateXAK from './generateXAK';
import axios from 'axios';
import { IAuth } from '../../types/user.types';
const { REACT_APP_4 } = process.env;

/** Funcion que revisa si hay una sesion activa antes de iniciar la app */
export default function useCachedResources(): boolean {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const unmounted = useRef(false);

  const { dispatch } = useClientStore();

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync(): Promise<void> {
      try {
        const apiKey = await generateXAK({ str2: REACT_APP_4 || '' });
        axios.interceptors.request.use((config: any) => {
          config.headers['x-a-k'] = apiKey;

          return config;
        });
        dispatch({ type: 'LOADING', payload: true });
        const tournamentSelected =
          await localStorage.getItem('tournamentSelected');
        if (tournamentSelected) {
          dispatch({
            type: 'SET_TOURNAMENT_ID',
            payload: Number(tournamentSelected),
          });
        }

        // Si existe una sesion activa
        const auth = await localStorage.getItem('auth');
        if (auth) {
          const authWeb: IAuth = JSON.parse(auth);
          const split = authWeb?.csrfToken?.split('-');
          const x2 = split?.[1].trim();
          const x3 = split?.[2].trim();
          const c = x2 && x3 ? await generateXAK({ str2: `${x2}${x3}` }) : '';
          axios.interceptors.request.use((config: any) => {
            config.headers['x-api-key'] = c;
            return config;
          });
          dispatch({ type: 'AUTH', payload: authWeb });
          const user = await useWhoami(authWeb.csrfToken);
          if (user) {
            dispatch({ type: 'SET_USER', payload: user });
          } else {
            dispatch({ type: 'LOGOUT' });
          }
        }
      } catch (e) {
        logger(e);
        dispatch({ type: 'LOGOUT' });
      } finally {
        setLoadingComplete(true);
        dispatch({ type: 'LOADING', payload: false });
      }
    }
    if (!unmounted.current) {
      void loadResourcesAndDataAsync();
    }

    return () => {
      unmounted.current = true;
    };
  }, []);

  return isLoadingComplete;
}
