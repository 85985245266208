import { Modal } from 'antd';
import React from 'react';
import { Column } from '../../../utils/layout';
import { SpinerScreen } from '../../commons';
import Pagination from '../../commons/pagination/Pagination';
import ResultHeaderTable from '../../screens/home/components/resultsSection/components/resultTable/resultHeaderTable/ResultHeaderTable';
import ResultItemTable from '../../screens/home/components/resultsSection/components/resultTable/resultItemTable/ResultItemTable';
import styles from './tableTicketResultsModal.module.css';
import { GroupedTickets } from '../../../types/ticket.types';
import { TicketName } from '../showPredictionsModal/ShowPredictionsModal';

interface TableTicketResultsModal {
  open?: boolean;
  onClose?: () => void;
  grouppedTickets?: GroupedTickets;
  totalTickets?: number;
  isLoading: boolean;
  isStarted: boolean;
  currentPage: number;
  onChangePagination: (newPage: number) => void;
  ticketName: TicketName;
  title: string;
}

const TableTicketResultsModal: React.FC<TableTicketResultsModal> = ({
  open,
  onClose,
  grouppedTickets,
  isLoading,
  isStarted,
  currentPage,
  totalTickets,
  onChangePagination,
  ticketName,
  title,
}) => {
  const resultsPerPage = 15;
  const restInitialIndex = grouppedTickets?.first?.length ? 4 : 1;
  const restPagesIndex =
    restInitialIndex === 1 ? (currentPage - 1) * resultsPerPage : 0;
  /*   const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 15;
  const { data, isLoading, refetch } = useGetAllTickets({
    page: String(currentPage),
    resultsPerPage: String(resultsPerPage),
    fixtureId: String(fixtureId),
  });
  const tickets = data?.results;

  const restInitialIndex = tickets?.firstTickets?.length ? 4 : 1;
  const restPagesIndex =
    restInitialIndex === 1 ? (currentPage - 1) * (resultsPerPage - 1) : 0;

  const onChangePagination = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);
 */

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
      closeIcon={<div className={styles.closeIcon}>X</div>}
    >
      <Column className={styles.content}>
        {isLoading ? (
          <SpinerScreen
            style={{ width: '500px', height: '600px', flex: undefined }}
          />
        ) : (
          <>
            <ResultHeaderTable isStarted={isStarted} title={title} />

            {!grouppedTickets ? <p>No hay resultados</p> : null}

            {grouppedTickets?.first?.map((t, i) => (
              <ResultItemTable
                key={`tick-${1}-${i}`}
                ticket={t}
                position={1}
                isStarted={isStarted}
                isFirstPosition
                ticketName={ticketName}
              />
            ))}
            {grouppedTickets?.second?.map((t, i) => (
              <ResultItemTable
                key={`tick-${i}-${2}`}
                ticket={t}
                position={2}
                isStarted={isStarted}
                isSecondPosition
                ticketName={ticketName}
              />
            ))}
            {grouppedTickets?.third?.map((t, i) => (
              <ResultItemTable
                key={`tick-${i}-${3}`}
                ticket={t}
                position={3}
                isStarted={isStarted}
                isThirdPosition
                ticketName={ticketName}
              />
            ))}

            {grouppedTickets?.rest?.map((t, i) => (
              <ResultItemTable
                key={`tick-${i + restInitialIndex}`}
                ticket={t}
                position={i + restInitialIndex + restPagesIndex}
                isStarted={isStarted}
                ticketName={ticketName}
              />
            ))}
            {totalTickets != undefined && totalTickets > resultsPerPage ? (
              <div className={styles.containerPagination}>
                <Pagination
                  onPageChange={onChangePagination}
                  current={currentPage}
                  total={totalTickets}
                  itemsPerPage={resultsPerPage}
                />
              </div>
            ) : null}
          </>
        )}
      </Column>
    </Modal>
  );
};

export default TableTicketResultsModal;
