import React, { useState } from 'react';
import { Drawer } from 'antd';
import styles from './menuMobile.module.css';
import Column from '../Column';
import useWriting from '../../../../copywriting/useWriting';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FixtureListModal,
  TournamentsListModal,
} from '../../../../components/modals';

type MenuMobile = {
  open?: boolean;
  onClose?: () => void;
};
const MenuMobile: React.FC<MenuMobile> = ({ open, onClose }) => {
  const writing = useWriting();
  const location = useLocation();
  const navigate = useNavigate();
  const [openTournamentsListModal, setOpenTournamentsListModal] =
    useState(false);
  const [openFixturesListModal, setOpenFixturesListModal] = useState(false);

  const currentPath = location.pathname;

  const handleMenuClick = (goPath: string) => {
    navigate(goPath);
  };

  const isHome = currentPath === '/';

  return (
    <Drawer
      placement={'left'}
      closable={false}
      onClose={onClose}
      open={open}
      key={'left'}
      width={'50%'}
    >
      <Column>
        <span
          className={`${styles.headerText} ${
            isHome ? styles.headerTextSelected : ''
          }`}
          onClick={() => handleMenuClick('/')}
        >
          {writing.menuTop.home}
        </span>

        <span
          className={`${styles.headerText} ${
            currentPath === '/instructions' ? styles.headerTextSelected : ''
          }`}
          onClick={() => handleMenuClick('/instructions')}
        >
          {writing.menuTop.instructions}
        </span>
        <span
          className={`${styles.headerText} ${
            currentPath === '/support' ? styles.headerTextSelected : ''
          }`}
          onClick={() => handleMenuClick('/support')}
        >
          {writing.menuTop.support}
        </span>
        <div className={styles.line} />
        <span
          className={`${styles.headerText}`}
          onClick={() => setOpenTournamentsListModal(true)}
        >
          Torneos
        </span>
        <span
          className={`${styles.headerText}`}
          onClick={() => setOpenFixturesListModal(true)}
        >
          Fechas
        </span>
      </Column>
      <TournamentsListModal
        open={openTournamentsListModal}
        onClose={() => setOpenTournamentsListModal(false)}
      />
      <FixtureListModal
        open={openFixturesListModal}
        onClose={() => setOpenFixturesListModal(false)}
      />
    </Drawer>
  );
};

export default MenuMobile;
