export type UnitPrice = 'USDT' | 'AR$';

function isInteger(numero: number) {
  if (numero % 1 == 0) {
    return true;
  } else {
    return false;
  }
}

const formatPrice = (num?: number, unit?: UnitPrice) => {
  if (!num) return `${unit || 'AR$'} 00.00`;

  const digitsConfig = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  if ((num >= 1 && num <= 9) || !isInteger(num)) {
    digitsConfig.maximumFractionDigits = 2;
    digitsConfig.minimumFractionDigits = 2;
  }

  const formattedNumber = num.toLocaleString('es-AR', digitsConfig);
  return `${unit || 'AR$'} ${formattedNumber}`;
};

export default formatPrice;
