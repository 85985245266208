import React, { useEffect, useRef, useState } from 'react';
import styles from './fixtureSection.module.css';
import { Column } from '../../../../../utils/layout';
import { Button } from 'antd';
import { FixtureAttributes } from '../../../../../types/fixture.types';
import { TournamentWithFixtureName } from '../../../../../types/tournament.types';
import { validateStartDate } from '../../../../../utils/hooks/handleDate';
import isValidateNumber from './components/utils/isValidateNumber';
import TourFixtureSection from './components/tourFixtureSection/TourFixtureSection';
import {
  useGetRound,
  useGetSavePredictionSecret,
} from '../../../../serverStore/queries';
import useClientStore from '../../../../clientStore/useClientStore';
import { RoundAttributes } from '../../../../../types/round.types';
import FixtureItemHome from './components/fixtureItemHome/FixtureItemHome';
import FixtureTitleItem from './components/fixtureTitleItem/FixtureTitleItem';
import FixtureName from './components/fixtureName/FixtureName';
import TournamentName from './components/tournamentName/TournamentName';
import RoundNames from './components/roundNames/RoundNames';
import filterNullPredictions from '../../../../../utils/hooks/filterNullPredictions';
import useSavePrediction, {
  DataSavePredictions,
  ParamsSavePredictions,
} from '../../../../serverStore/mutations/prediction/useSavePrediction';
import { decryptData, fetchPredictions, isEqual } from '../../../../../utils';
import AuthModal, { AuthType } from '../../../../modals/auth/AuthModal';
import { CustomTooltip } from '../../../../commons';
import { useSearchUserPrediction } from '../../../../serverStore/mutations';

type TFixtureSection = {
  fixture?: FixtureAttributes;
  isFixtureFinishedOpen: boolean;
  tournament?: TournamentWithFixtureName;
};

export type TOnChangePredictionProps = {
  matchId: number;
  team: 'local' | 'visitor';
  increase: boolean;
};

export type SimplePrediction = {
  matchId: number;
  predictionLocalTeam?: number;
  predictionVisitingTeam?: number;
};

export type PredictionsStorage = {
  predictions: SimplePrediction[];
  roundId: number;
};

const getInitialPredictions = (
  savePredictionsStorage?: PredictionsStorage,
  round?: RoundAttributes,
) => {
  if (
    savePredictionsStorage?.predictions &&
    savePredictionsStorage?.roundId === round?.id
  ) {
    const newPred = round?.matches?.map((m) => {
      const pred = savePredictionsStorage.predictions?.find(
        (p) => p.matchId === m.id,
      );
      if (pred) {
        return {
          matchId: m.id,
          predictionLocalTeam: pred.predictionLocalTeam,
          predictionVisitingTeam: pred.predictionVisitingTeam,
        };
      } else {
        return {
          matchId: m.id,
          predictionLocalTeam: undefined,
          predictionVisitingTeam: undefined,
        };
      }
    });
    return newPred || savePredictionsStorage.predictions;
  }
  if (round?.matches?.length) {
    return round.matches.map((m) => {
      return {
        matchId: m.id,
        predictionLocalTeam: undefined,
        predictionVisitingTeam: undefined,
      };
    });
  }
  return [];
};

const saveStoragePredictions = (p: SimplePrediction[], roundId: number) => {
  const savePredictions: PredictionsStorage = {
    predictions: filterNullPredictions(p),
    roundId,
  };
  localStorage.setItem('savePredictions', JSON.stringify(savePredictions));
};

const FixtureSection: React.FC<TFixtureSection> = ({
  fixture,
  tournament,
  isFixtureFinishedOpen,
}) => {
  const refTournamentChangeName = useRef(null);
  const refFixtureChangeName = useRef(null);
  const {
    fixtureSelected: { fixtureId },
    loggedUser,
    dispatch,
  } = useClientStore();
  const [roundId, setRoundId] = useState<number | undefined>(undefined);

  const [authModal, setAuthModal] = useState<{
    open?: boolean;
    authType?: AuthType;
  }>({});

  const { data: round, refetch: refetchRound } = useGetRound({
    fixtureId: fixtureId,
    roundId,
  });

  const { data: secretSavePredictions } = useGetSavePredictionSecret();
  const { mutate: savePredictions } = useSavePrediction();
  const {
    data: responsePredictions,
    mutate: searchMutate,

    reset,
  } = useSearchUserPrediction();

  const [predictions, setPredictions] = useState<SimplePrediction[]>([]);
  const [initalStatePrediction, setInitalStatePrediction] = useState<
    SimplePrediction[]
  >([]);

  const isChangePredictions = !isEqual(predictions, initalStatePrediction);
  const imageTournament = tournament?.image;

  /*   const [waringConfirm, setWaringConfirm] = useState<{
    open: boolean;
    description?: string;
    title?: string;
    cancelText?: string;
  }>({ open: false }); */

  const isRoundDateValidate = validateStartDate(round?.startDate);
  const isRoundFinished = !validateStartDate(round?.finishDate);

  const matches = round?.matches;

  const onChangeRoundId = (roundId?: number) => {
    setRoundId(roundId);
    dispatch({
      type: 'SET_ROUND_ID',
      payload: roundId,
    });
  };

  const onChangePrediction = ({
    increase,
    matchId,
    team,
  }: TOnChangePredictionProps) => {
    const newPredictions = predictions.map((p) => {
      if (p.matchId === matchId) {
        let newPredLocal =
          p.predictionLocalTeam != null ? p.predictionLocalTeam : 0;

        let newPredVisit: number =
          p.predictionVisitingTeam != null ? p.predictionVisitingTeam : 0;
        if (team === 'local') {
          if (increase) {
            newPredLocal = newPredLocal + 1;
          } else {
            newPredLocal = newPredLocal - 1;
          }
        }
        if (team === 'visitor') {
          if (increase) {
            newPredVisit = newPredVisit + 1;
          } else {
            newPredVisit = newPredVisit - 1;
          }
        }

        return {
          ...p,
          predictionLocalTeam: isValidateNumber(newPredLocal)
            ? newPredLocal
            : 0,
          predictionVisitingTeam: isValidateNumber(newPredVisit)
            ? newPredVisit
            : 0,
        };
      } else {
        return p;
      }
    });
    setPredictions(newPredictions);
    if (round?.id && tournament?.id) {
      saveStoragePredictions(newPredictions, round.id);
    }
  };

  const handleAuthOpen = (authType?: AuthType) => {
    setAuthModal({ open: true, authType });
  };

  const handleAuthClose = () => {
    setAuthModal({ open: false });
  };

  const handleConfirmResults = async () => {
    if (!loggedUser) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'warning',
          message: 'Iniciar sesion',
          description: 'Inicia sesion o registrate para guardar resultados',
        },
      });
      handleAuthOpen('login');

      return;
    }
    const decryptSavePredictionsSecret = await decryptData(
      secretSavePredictions,
    );
    const x = decryptSavePredictionsSecret?.split('+')?.[0];
    const savePredictionsSecret = decryptSavePredictionsSecret?.split('+')?.[1];

    if (
      round?.id &&
      tournament?.id &&
      predictions.length &&
      fixture?.id &&
      loggedUser?.id &&
      secretSavePredictions
    ) {
      const dataSavePredictions: DataSavePredictions = {
        predictions: filterNullPredictions(predictions),
        roundId: round?.id,
        savePredictionsSecret,
        fixtureId: fixture?.id,
        fixtureTicketType: 'normal',
        tournamentTicketType: 'normal',
        userId: loggedUser.id,
      };

      const params: ParamsSavePredictions = { data: dataSavePredictions, x };
      savePredictions(params, {
        onSuccess: () => {
          setInitalStatePrediction(predictions);
        },
      });
    }
  };

  /* ACTUALIZA LAS PREDICCIONES CUANDO CAMBIA DE RONDA O SE LOGUEA UN USUARIO */
  useEffect(() => {
    const storedPredictionsJSON = localStorage.getItem('savePredictions');
    const savePredictionsStorage: PredictionsStorage | undefined =
      storedPredictionsJSON ? JSON.parse(storedPredictionsJSON) : null;
    if (isRoundFinished && round?.id === savePredictionsStorage?.roundId) {
      localStorage.removeItem('savePredictions');
    }
    /* Si se loguea un usuario responsePredictions tiene las predicciones del usuario */
    if (responsePredictions) {
      /* Si existen predicciones y la ronda es la misma seteo las predicciones */
      if (
        responsePredictions?.predictions.length &&
        responsePredictions?.roundId === round?.id
      ) {
        const initialUserPredictions = getInitialPredictions(
          responsePredictions as any,
          round,
        );
        setPredictions(initialUserPredictions);
        setInitalStatePrediction(initialUserPredictions);
        return;
      }
      /* Si se logueo un usuario y no existen predicciones seteo las predicciones del storage y las iniciales como vacias */
      if (!responsePredictions?.predictions?.length) {
        const initialStoragePredictions = getInitialPredictions(
          savePredictionsStorage,
          round,
        );
        setPredictions(initialStoragePredictions);
        setInitalStatePrediction([]);
        return;
      }
    } else {
      /* Si no existe user logueado no hay responsePredictions y solo seteo las predicciones del storage */
      const initialStoragePredictions = getInitialPredictions(
        savePredictionsStorage,
        round,
      );
      setPredictions(initialStoragePredictions);
      setInitalStatePrediction(initialStoragePredictions);
    }
  }, [round?.id, responsePredictions]);

  useEffect(() => {
    if (!loggedUser) {
      reset();
    }
    //Si existe round id y no busco predicciones o las predicciones corresponden a otro round id volvera hacer el get
    if (
      round?.id &&
      (!responsePredictions || responsePredictions?.roundId !== round?.id) &&
      secretSavePredictions &&
      loggedUser
    ) {
      fetchPredictions({
        roundId: round.id,
        secretSavePredictions,
        searchMutate,
      });
    }

    /* SI EXISTE ROUND ID PERO NO ESTA SELECCIONADO ESE ROUND ID LO SETEO */
    if (round?.id && !roundId) {
      onChangeRoundId(round.id);
    }
  }, [round?.id, secretSavePredictions, responsePredictions, loggedUser]);

  useEffect(() => {
    if (roundId && round?.id !== roundId) {
      refetchRound();
    }
  }, [roundId]);

  return (
    <div className={styles.containerFixtureSection}>
      <div
        className={`${styles.backgroundImg} ${styles.backgroundImg} `}
        style={{
          backgroundImage: `url(${imageTournament || '../../../../../assets/stadium.jpg'})`,
        }}
      />

      <Column className={styles.content}>
        <TournamentName
          refTournamentChangeName={refTournamentChangeName}
          tournament={tournament}
        />

        <FixtureName
          refFixtureChangeName={refFixtureChangeName}
          fixture={fixture}
        />
        {fixture?.rounds && fixture.rounds.length > 1 ? (
          <RoundNames
            roundsName={fixture.rounds}
            roundChecked={round}
            onChangeRoundId={onChangeRoundId}
          />
        ) : null}

        {fixture ? (
          <>
            <Column className={styles.containerTable}>
              <FixtureTitleItem isStarted={!isRoundDateValidate} />
              <Column className={styles.containerFixtures}>
                {matches?.length
                  ? matches.map((m, i) => (
                      <FixtureItemHome
                        key={`pred-${i}`}
                        prediction={predictions.find((p) => p.matchId === m.id)}
                        match={m}
                        onChangePrediction={onChangePrediction}
                        isFixtureValidDate={isRoundDateValidate}
                      />
                    ))
                  : null}
              </Column>

              {!isRoundFinished ? (
                <CustomTooltip
                  title={'No hay cambios pendientes'}
                  disabled={isChangePredictions}
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    className={styles.buttonConfirm}
                    onClick={handleConfirmResults}
                    disabled={!isChangePredictions}
                  >
                    Guardar Resultados
                  </Button>
                </CustomTooltip>
              ) : null}
            </Column>
          </>
        ) : null}
      </Column>

      <TourFixtureSection
        ref1={refTournamentChangeName}
        ref2={refFixtureChangeName}
        isFixtureFinishedOpen={isFixtureFinishedOpen}
      />

      {authModal.authType && authModal.open ? (
        <AuthModal
          open={authModal.open}
          authType={authModal.authType}
          handleClose={handleAuthClose}
        />
      ) : null}
    </div>
  );
};

export default FixtureSection;
