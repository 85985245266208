import {
  DataSearchPredictions,
  ParamsSearchPredictions,
} from '../../components/serverStore/mutations/prediction/useSearchUserPrediction';
import decryptData from './decryptData';

export const getParamsSearchPredictions = async ({
  secretSavePredictions,
  roundId,
  email,
  includeMatch,
  includeTeam,
}: Omit<DataSearchPredictions, 'savePredictionsSecret'> & {
  secretSavePredictions: string;
}) => {
  const decryptSavePredictionsSecret = await decryptData(secretSavePredictions);
  const x = decryptSavePredictionsSecret?.split('+')?.[0];
  const savePredictionsSecret = decryptSavePredictionsSecret?.split('+')?.[1];

  const dataSearchPredictions: DataSearchPredictions = {
    roundId,
    savePredictionsSecret,
    email,
    includeMatch,
    includeTeam,
  };

  const params: ParamsSearchPredictions = { data: dataSearchPredictions, x };
  return params;
};

const fetchPredictions = async ({
  roundId,
  secretSavePredictions,
  email,
  includeMatch,
  searchMutate,
}: {
  roundId: number;
  secretSavePredictions: string;
  email?: string;
  searchMutate: (params: ParamsSearchPredictions) => void;
  includeMatch?: boolean;
}) => {
  const paramsSearch = await getParamsSearchPredictions({
    roundId,
    secretSavePredictions,
    email,
    includeMatch,
  });
  if (roundId && secretSavePredictions) {
    searchMutate(paramsSearch);
  }
};

export default fetchPredictions;
