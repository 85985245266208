import React from 'react';
import { RoundAttributes } from '../../../../../../../types/round.types';
import { capitalizeFirstLetter } from '../../../../../../../utils';
import { Flex } from '../../../../../../../utils/layout';
import { Tag } from '../../../../../../commons';
import styles from './roundNames.module.css';

interface IRoundNameProps {
  roundsName: RoundAttributes[];
  roundChecked?: RoundAttributes;
  onChangeRoundId: (roundId?: number) => void;
}

const RoundName: React.FC<IRoundNameProps> = ({
  roundsName,
  roundChecked,
  onChangeRoundId,
}) => {
  return (
    <Flex className={styles.containerTagNames}>
      {roundsName.map<React.ReactNode>((r) => (
        <Tag
          label={capitalizeFirstLetter(r.name) || r.name}
          key={r.id}
          checked={roundChecked?.id === r.id}
          onClick={() => onChangeRoundId(r?.id)}
        />
      ))}
    </Flex>
  );
};

export default RoundName;
