import React, { useState } from 'react';
import { useGetAllFixtureTickets } from '../../../../../../../serverStore/queries';
import TableTicketResultsModal from '../../../../../../../modals/tableTicketsResults/TableTicketResultsModal';

interface IFixtureTicketsTableProps {
  fixtureId: number;
  openTableModal: boolean;
  onClose?: () => void;
  isStarted: boolean;
  fixtureName: string;
}

const FixtureTicketsTable: React.FC<IFixtureTicketsTableProps> = ({
  fixtureId,
  openTableModal,
  onClose,
  isStarted,
  fixtureName,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetAllFixtureTickets({
    groupped: true,
    page: String(currentPage),
    resultsPerPage: '15',
    fixtureId: String(fixtureId),
    enabledRequest: !!fixtureId,
  });

  return (
    <TableTicketResultsModal
      currentPage={currentPage}
      grouppedTickets={data?.results}
      onChangePagination={(newPage) => setCurrentPage(newPage)}
      totalTickets={data?.count}
      isStarted={isStarted}
      isLoading={isLoading}
      onClose={onClose}
      open={openTableModal}
      ticketName='fixtureTicket'
      title={`resultados - ${fixtureName}`.toLocaleUpperCase()}
    />
  );
};

export default FixtureTicketsTable;
