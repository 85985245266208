import axios, { AxiosResponse } from 'axios';
import { UserAttributes } from '../../../../types/user.types';
import { decryptData } from '../../../../utils';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import generateXAK from '../../../../utils/hooks/generateXAK';

const useGetUser = async (userId?: number): Promise<UserAttributes> => {
  const response: UserAttributes = await axiosFetch(
    `/user${userId ? `?userId=${userId}` : ''}`,
  );

  return response;
};

export const useWhoami = async (x?: string): Promise<UserAttributes> => {
  const split = x?.split('-');
  const x1 = split?.[0].trim();
  const x2 = split?.[1].trim();
  const x3 = split?.[2].trim();
  const c = x2 && x3 ? await generateXAK({ str2: `${x2}${x3}` }) : '';
  const response: AxiosResponse<UserAttributes> = await axios('/user', {
    headers: {
      authorization: `Bearer ${x1}`,
      'x-api-key': c,
    },
    withCredentials: true,
  });

  const user = decryptData(response.data);
  return user;
};
export default useGetUser;
