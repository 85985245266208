import { useQuery } from '@tanstack/react-query';
import { FixtureAttributes } from '../../../../types/fixture.types';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';

type Params = {
  fixtureId?: number;
  tournamentId?: number;
  enabledRequest?: boolean;
};

const getFixture = async (params: Params): Promise<FixtureAttributes> => {
  const { fixtureId, tournamentId } = params;
  const response: FixtureAttributes = await axiosFetch(
    `/fixture/id/${fixtureId || ''}?include=rounds&${
      tournamentId ? `&tournamentId=${tournamentId}` : ''
    }`,
  );

  return response;
};

export default function useGetFixture(params: Params) {
  return useQuery({
    queryKey: [`fixture-${params?.fixtureId || 'last'}`],
    queryFn: () => getFixture(params),
    enabled:
      params.enabledRequest != undefined
        ? params.enabledRequest
        : !!params.tournamentId,
  });
}
