import React from 'react';

interface CupIconProps {
  size?: string;
  color?: string;
  className?: string;
}

const CupIcon: React.FC<CupIconProps> = ({ size, color, className }) => {
  return (
    <svg
      fill={color ?? 'white'}
      height={size ?? '16px'}
      width={size ?? '16px'}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 490.4 490.4'
      className={className}
    >
      <g>
        <path
          d='M371.2,0h-252v40.4H33v66.3c0,47.7,38.6,86.4,86.2,86.4h11.2c10.8,23.8,28.7,43.7,51,56.9c23.4,13.8,37.2,39.6,37.2,66.9
          v52.4h-30.9c-9.5,0-17.3,7.7-17.3,17.3v22.7H144v81.1h202.3v-81.1h-26.4v-22.7c0-9.6-7.7-17.3-17.3-17.3h-30.9v-52.4
          c0-27.3,13.8-53.1,37.2-66.9c22.3-13.2,40.3-33.1,51-56.9h11.3c47.6,0,86.2-38.7,86.2-86.4V40.3h-86.2V0z M119.2,149.3
          c-23.5,0-42.5-19.1-42.5-42.6V84.1h42.5v57c0,2.8,0.1,5.5,0.3,8.3h-0.3V149.3z M280.2,413.3v43.4h-70v-43.4H280.2z M309.4,121.8
          l-20.7,17.5l6.5,26.4c1.3,5.4,0.1,11-3.3,15.4s-8.7,7-14.2,7c-3.3,0-6.6-0.9-9.5-2.7l-23-14.3l-23,14.3c-7.6,4.7-18.2,2.8-23.7-4.2
          c-3.4-4.4-4.6-10-3.3-15.4l6.5-26.4L181,121.9c-5.6-4.8-7.8-12.4-5.5-19.4s8.5-11.9,15.8-12.4l27-2L228.5,63
          c2.8-6.8,9.3-11.2,16.7-11.2s13.9,4.4,16.7,11.2l10.2,25.1l27,2c7.3,0.5,13.6,5.4,15.8,12.4C317.2,109.5,315.1,117.1,309.4,121.8z
           M413.7,84.1v22.6c0,23.5-19.1,42.6-42.6,42.6h-0.3c0.2-2.7,0.3-5.5,0.3-8.3V84.1H413.7z M257,108.9l31.2,2.3l-23.9,20.2l7.5,30.5
          l-26.6-16.6l-26.6,16.5l7.5-30.5l-23.9-20.1l31.2-2.3l11.8-29.1L257,108.9z'
        />
      </g>
    </svg>
  );
};

export default CupIcon;
