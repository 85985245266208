type GetMatchResultProps = {
  predictionLocalTeam?: number;
  predictionVisitingTeam?: number;
  resultLocalTeam?: number;
  resultVisitingTeam?: number;
};

const getMatchResult = ({
  predictionLocalTeam,
  predictionVisitingTeam,
  resultLocalTeam,
  resultVisitingTeam,
}: GetMatchResultProps) => {
  const isPredicted =
    predictionLocalTeam != null && predictionVisitingTeam != null;

  const isResultsMatch = resultLocalTeam != null && resultVisitingTeam != null;

  const isPredictionLocal =
    isPredicted && predictionLocalTeam > predictionVisitingTeam;
  const isPredictionVisitor =
    isPredicted && predictionVisitingTeam > predictionLocalTeam;
  const isPredictionTie =
    isPredicted && predictionLocalTeam === predictionVisitingTeam;
  const isLocalWin = isResultsMatch && resultLocalTeam > resultVisitingTeam;
  const isVisitorWin = isResultsMatch && resultVisitingTeam > resultLocalTeam;
  const isTieResult = isResultsMatch && resultLocalTeam === resultVisitingTeam;

  const isExactResult =
    isPredicted &&
    isResultsMatch &&
    predictionLocalTeam === resultLocalTeam &&
    predictionVisitingTeam === resultVisitingTeam;

  const isRightResult =
    !isExactResult &&
    isPredicted &&
    isResultsMatch &&
    ((isPredictionLocal && isLocalWin) ||
      (isPredictionVisitor && isVisitorWin) ||
      (isPredictionTie && isTieResult));

  return {
    isExactResult,
    isRightResult,
    isLocalWin,
    isVisitorWin,
    isTieResult,
  };
};

export default getMatchResult;
