import React from 'react';

import { Column } from '../../../../utils/layout';
import styles from './userCredits.module.css';

const UserCredits: React.FC = () => {
  return (
    <Column style={{ flex: 1 }}>
      <div className={styles.navbar}></div>

      <div>Recargar</div>
      <div>Retirar</div>
    </Column>
  );
};

export default UserCredits;
