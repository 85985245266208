import React from 'react';
import { Flex } from '../../../../../../../utils/layout';
import styles from './predictionResultHeader.module.css';

const PredictionResultHeader: React.FC = () => {
  return (
    <Flex className={`${styles.container}`}>
      <Flex className={styles.teams}>
        <p className={styles.containerTeam}>LOCAL</p>
        <p className={styles.containerResult}>RESULTADO</p>
        <p className={styles.containerTeam}>VISITANTE</p>
      </Flex>
      <p className={styles.containerPointsMobile}>Pts</p>
      <p className={styles.containerPoints}>PUNTOS</p>
      <p className={styles.containerStatus}>FECHA</p>
    </Flex>
  );
};

export default PredictionResultHeader;
