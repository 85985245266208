import { useQuery } from '@tanstack/react-query';
import { StatusTournament } from '../../../../types/tournament.types';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';

export type ResponseFixtureNames = {
  id: number;
  name: string;
  status: StatusTournament;
}[];
const getFixtureNames = async (
  tournamentId?: number,
): Promise<ResponseFixtureNames> => {
  const response: ResponseFixtureNames = await axiosFetch(
    `/fixture/names/${tournamentId}`,
  );
  return response;
};

export default function useGetFixtureNames(tournamentId?: number) {
  return useQuery({
    queryKey: ['fixture-names'],
    queryFn: () => getFixtureNames(tournamentId),
    enabled: !!tournamentId,
  });
}
