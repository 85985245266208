import axios, { AxiosRequestConfig } from 'axios';
import { IAuth } from '../../types/user.types';
import generateXAK from '../hooks/generateXAK';

type IAxiosFetch = (url: string, options?: AxiosRequestConfig) => Promise<any>;

const axiosFetch: IAxiosFetch = async (url, options) => {
  const authStorage = localStorage.getItem('auth');
  /* Configuraciíon para request web   */
  if (authStorage) {
    const authWeb: IAuth = JSON.parse(authStorage);
    const split = authWeb?.csrfToken?.split('-');
    const x2 = split?.[1].trim();
    const x3 = split?.[2].trim();
    const c = x2 && x3 ? await generateXAK({ str2: `${x2}${x3}` }) : '';
    const headers = {
      ...options?.headers,
      authorization: `Bearer ${authWeb.csrfToken.split('-')?.[0].trim()}`,
      'x-api-key': c,
    };

    return axios(url, {
      ...options,
      headers,
      withCredentials: true,
    }).then((response) => {
      return response.data;
    });
  }
  return axios(url, {
    ...options,
    headers: { ...options?.headers },
    withCredentials: true,
  }).then((response) => response.data);
};

export default axiosFetch;
