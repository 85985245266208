import { useQuery } from '@tanstack/react-query';

import axiosFetch from '../../../../../utils/axiosConfig/axiosFetch';
import {
  GroupedTickets,
  ResponsePaginatedFixtureTickets,
} from '../../../../../types/ticket.types';
import { decryptGruppedTickets } from '../../../../../utils';

type TicketQueryParams = {
  page?: string;
  resultsPerPage?: string;
  searchText?: string;
  groupped?: boolean;
  enabledRequest?: boolean;
  fixtureId?: string;
};
const getFixtureTickets = async (
  params: TicketQueryParams,
): Promise<ResponsePaginatedFixtureTickets> => {
  const { groupped, page, resultsPerPage, searchText, fixtureId } = params;
  let queryString = '';
  if (page) queryString += `page=${page}&`;
  if (resultsPerPage) queryString += `resultsPerPage=${resultsPerPage}&`;
  if (searchText) queryString += `searchText=${searchText}&`;
  if (groupped) queryString += `groupped=${groupped}&`;
  if (fixtureId) queryString += `fixtureId=${fixtureId}&`;

  // Eliminar el último "&" si existe
  queryString = queryString.replace(/&$/, '');

  const response: ResponsePaginatedFixtureTickets = await axiosFetch(
    `/ticket/fixture/all?${queryString}`,
  );
  const decryptedUserTickets: GroupedTickets | undefined =
    await decryptGruppedTickets(response?.results);
  return { ...response, results: decryptedUserTickets };
};

export default function useGetAllFixtureTickets(params: TicketQueryParams) {
  return useQuery({
    queryKey: ['tickets-fixture'],
    queryFn: () => getFixtureTickets(params),
    enabled: params.enabledRequest,
  });
}
