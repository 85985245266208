import { RoundAttributes } from './round.types';
import { TournamentAttributes } from './tournament.types';

export type StatusFixture = 'inProgress' | 'finished' | 'toStart' | 'created';

export interface FixtureAttributes {
  id?: number;
  tournamentId?: number;
  name: string;
  startDate: Date;
  finishDate: Date;
  status: StatusFixture;
  tournament?: TournamentAttributes;
  price?: number;
  rounds?: RoundAttributes[];
}

export interface ResponsePaginatedFixtures {
  count: number;
  next: string | null;
  previous: string | null;
  results: FixtureAttributes[];
}

export const statusFixture: { [key in StatusFixture]: string } = {
  inProgress: 'En curso',
  toStart: 'Por iniciar',
  finished: 'Finalizado',
  created: 'Creado',
};
