import { Button, Checkbox, Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Flex } from '../../../utils/layout';
import styles from './adsModal.module.css';
import { formatPrice } from '../../../utils';

interface IWinModalProps {
  open?: boolean;
  onClose?: () => void;
  adsId: number;
}

const AdsModal: React.FC<IWinModalProps> = ({ open, onClose, adsId }) => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleCloseButton = () => {
    if (checked) {
      localStorage.setItem('ads', JSON.stringify(adsId));
    }
    onClose && onClose();
  };

  const handleConfirButton = () => {
    if (checked) {
      localStorage.setItem('ads', JSON.stringify(adsId));
    }
    navigate('/?tournamentId=1');
    onClose && onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
      closeIcon={<div className={styles.closeIcon}>X</div>}
    >
      <div>
        <div className={styles.backgroundImg} />
        <Column className={styles.content}>
          <p className={styles.title}>Fase Final Liga Argentina</p>
          <Column className={styles.bodytext}>
            <p>
              Arranca la ultima fase de la Liga Argentina. El prode incluye
              Cuartos de final, Semi final y Final.
            </p>
            <p>
              Se comienza pronosticando los cuartos de final. Una vez definidas
              las llaves de la semi final se podran pronosticar esos partidos y
              lo mismo para el partido de la final.{' '}
            </p>
            <p>
              Como en todos los prodes, tenes tiempo hasta 5 minutos antes de
              que inicie el primer partido de cada fase para editar tus
              pronosticos.
            </p>
            <p>
              Gana el o los que sumen mas puntos en la totalidad de los partidos
              incluyendo la final.
            </p>
            <p>No te quedes sin armar tu prode !</p>
          </Column>

          <div className={styles.prizeContainer}>
            <p className={styles.prizeText}>Valor del Prode</p>
            <p className={styles.prizeAmount}>{formatPrice(3000, 'AR$')}</p>
          </div>

          <Flex
            className={styles.checkbox}
            onClick={() => setChecked(!checked)}
          >
            <Checkbox checked={checked} />

            <p>No volver a mostrar</p>
          </Flex>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleConfirButton}
            className={styles.buttonConfirm}
          >
            Crear Fixture
          </Button>
          <Button
            type='dashed'
            htmlType='submit'
            onClick={handleCloseButton}
            className={styles.button}
          >
            Cerrar
          </Button>
        </Column>
      </div>
    </Modal>
  );
};

export default AdsModal;
