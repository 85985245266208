import { Modal } from 'antd';
import React from 'react';
import { Column } from '../../../utils/layout';
import styles from './showPredictionModal.module.css';
import FixturePredictionResult from './components/fixturePredictionResult/FixturePredictionResult';
import TournamentPredictionResult from './components/tournamentPredictionResult/TournamentPredictionResult';

export type TicketName = 'fixtureTicket' | 'tournamentTicket';
interface ITicketResultsModalProps {
  ticketName: TicketName;
  email: string;
  open?: boolean;
  onClose?: () => void;
}

const ShowPredictionsModal: React.FC<ITicketResultsModalProps> = ({
  open,
  onClose,
  ticketName,
  email,
}) => {
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        footer={null}
        className={styles.modal}
        closeIcon={<div className={styles.closeIcon}>X</div>}
      >
        <div>
          <div className={styles.backgroundImg} />
          <Column className={styles.content}>
            {ticketName === 'fixtureTicket' ? (
              <FixturePredictionResult email={email} />
            ) : null}
            {ticketName === 'tournamentTicket' ? (
              <TournamentPredictionResult email={email} />
            ) : null}
          </Column>
        </div>
      </Modal>
    </>
  );
};

export default ShowPredictionsModal;
