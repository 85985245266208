import { Spin } from 'antd';
import React from 'react';
import styles from './spinerScreen.module.css';
interface ISpinerScreenProps {
  size?: 'small' | 'large';
  style?: React.CSSProperties;
  className?: string;
}

const SpinerScreen: React.FC<ISpinerScreenProps> = ({
  size,
  style,
  className,
}) => {
  return (
    <div style={style} className={`${styles.spiner} ${className}`}>
      <Spin size={size || 'large'} />
    </div>
  );
};

export default SpinerScreen;
