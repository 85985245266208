import React from 'react';

import { GroupedTickets } from '../../../../../../../types/ticket.types';
import useClientStore from '../../../../../../clientStore/useClientStore';
import { Column, Flex } from '../../../../../../../utils/layout';
import ResultHeaderTable from './resultHeaderTable/ResultHeaderTable';
import ResultItemTable from './resultItemTable/ResultItemTable';
import { CustomTooltip } from '../../../../../../commons';
import styles from './resultTable.module.css';
import { TicketName } from '../../../../../../modals/showPredictionsModal/ShowPredictionsModal';

type TResultTable = {
  grouppedTickets: GroupedTickets;
  totalTickets: number;
  onClickShowMoreTickets: () => void;
  isStarted: boolean;
  ticketName: TicketName;
  title: string;
};

const ResultTable: React.FC<TResultTable> = ({
  grouppedTickets,
  totalTickets,
  onClickShowMoreTickets,
  isStarted,
  ticketName,
  title,
}) => {
  const { loggedUser } = useClientStore();

  const restInitialIndex = grouppedTickets?.first?.length ? 4 : 1;

  return (
    <Column alignItems={'center'} className={styles.containerResultTable}>
      <ResultHeaderTable isStarted={isStarted} title={title} />
      <Column style={{ gap: 4 }} className={styles.containerItemsTable}>
        {grouppedTickets?.first?.map((t, i) => (
          <ResultItemTable
            key={`tick-${1}-${i}`}
            ticket={t}
            position={1}
            isStarted={isStarted}
            isFirstPosition
            ticketName={ticketName}
          />
        ))}
        {grouppedTickets?.second?.map((t, i) => (
          <ResultItemTable
            key={`tick-${i}-${2}`}
            ticket={t}
            position={2}
            isStarted={isStarted}
            isSecondPosition
            ticketName={ticketName}
          />
        ))}
        {grouppedTickets?.third?.map((t, i) => (
          <ResultItemTable
            key={`tick-${i}-${3}`}
            ticket={t}
            position={3}
            isStarted={isStarted}
            isThirdPosition
            ticketName={ticketName}
          />
        ))}

        {grouppedTickets?.rest?.map((t, i) => (
          <ResultItemTable
            key={`tick-${i + restInitialIndex}`}
            ticket={t}
            position={i + restInitialIndex}
            isStarted={isStarted}
            ticketName={ticketName}
          />
        ))}
        {totalTickets > 10 ? (
          <Flex className={styles.totalTickets}>
            <p>{totalTickets} Tickets Totales</p>
            <p>-</p>
            <CustomTooltip
              title={'Inicia sesion para ver'}
              disabled={!!loggedUser}
            >
              <p
                className={styles.showMoreTickets}
                onClick={onClickShowMoreTickets}
              >
                Ver mas
              </p>
            </CustomTooltip>
          </Flex>
        ) : null}
      </Column>
    </Column>
  );
};

export default ResultTable;
