import { GenericTickets, GroupedTickets } from '../../types/ticket.types';
import decryptData from './decryptData';

export const decryptTicketUser = async (
  tickets: GenericTickets[] | undefined,
) => {
  if (!tickets?.length) return [];

  const decryptedTickets = await Promise.all(
    tickets.map(async (t) => {
      if (t.user) {
        const parU = await decryptData(t.user);
        return { ...t, user: parU };
      }
      return t; // Si no hay usuario, devolvemos el ticket sin modificar
    }),
  );

  return decryptedTickets;
};

const decryptGruppedTickets = async (
  grouppedTicket?: GroupedTickets,
): Promise<GroupedTickets | undefined> => {
  if (!grouppedTicket) return undefined;
  const decryptedFirstTickets = await decryptTicketUser(grouppedTicket.first);
  const decryptedSecondTickets = await decryptTicketUser(grouppedTicket.second);
  const decryptedThirdTickets = await decryptTicketUser(grouppedTicket.third);

  const decryptedRestTickets = await decryptTicketUser(grouppedTicket.rest);

  return {
    first: decryptedFirstTickets,
    second: decryptedSecondTickets,
    third: decryptedThirdTickets,
    rest: decryptedRestTickets,
  };
};

export default decryptGruppedTickets;
