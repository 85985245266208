import React from 'react';
import styles from './footer.module.css';
import { TikTokOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const ItemIcon: React.FC<{ icon: JSX.Element; name: string; url: string }> = ({
  icon,
  name,
  url,
}) => {
  return (
    <Tooltip title={name}>
      <div className={styles.icon} onClick={() => window.open(url, '_blank')}>
        {icon}
      </div>
    </Tooltip>
  );
};

const Footer: React.FC = () => {
  return (
    <div className={styles.footerText}>
      <div className={styles.containerIcons}>
        <ItemIcon
          name='Facebook'
          icon={<i className='fab fa-facebook' />}
          url='https://www.facebook.com/profile.php?id=61556748842145'
        />
        <ItemIcon
          name='Instagram'
          icon={<i className='fab fa-instagram'></i>}
          url='https://www.instagram.com/fixtuwin'
        />
        <ItemIcon
          name='X (twitter)'
          icon={<i className='fab fa-twitter'></i>}
          url='https://twitter.com/Fixtuwin'
        />
        <ItemIcon
          name='Tik Tok'
          icon={<TikTokOutlined />}
          url='https://www.tiktok.com/@fixtuwinok?lang=es'
        />
      </div>
      <p>&copy; 2024 Fixtuwin</p>
    </div>
  );
};

export default Footer;
