import React from 'react';
import { FixtureAttributes } from '../../../../../types/fixture.types';
import { Flex } from '../../../../../utils/layout';

import styles from './resultSection.module.css';
import FixtureResult from './components/fixtureResult/FixtureResult';
import { TournamentWithFixtureName } from '../../../../../types/tournament.types';
import TournamentResult from './components/tournamentResult/TournamentResult';

type TResultSection = {
  fixture?: FixtureAttributes;
  tournament?: TournamentWithFixtureName;
};
const ResultSection: React.FC<TResultSection> = ({ fixture, tournament }) => {
  return (
    <Flex className={styles.containerResultSection}>
      {fixture ? <FixtureResult fixture={fixture} /> : null}
      <div className={styles.separator}></div>
      {tournament ? <TournamentResult tournament={tournament} /> : null}
    </Flex>
  );
};

export default ResultSection;
