import React, { useState } from 'react';
import { useGetAllTournamentTickets } from '../../../../../../../serverStore/queries';
import TableTicketResultsModal from '../../../../../../../modals/tableTicketsResults/TableTicketResultsModal';

interface ITournamentTicketsTableProps {
  tournamentId: number;
  openTableModal: boolean;
  onClose?: () => void;
  isStarted: boolean;
  tournamentName: string;
}

const TournamentTicketsTable: React.FC<ITournamentTicketsTableProps> = ({
  tournamentId,
  openTableModal,
  onClose,
  isStarted,
  tournamentName,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetAllTournamentTickets({
    groupped: true,
    page: String(currentPage),
    resultsPerPage: '15',
    tournamentId: String(tournamentId),
    enabledRequest: !!tournamentId,
  });

  return (
    <TableTicketResultsModal
      currentPage={currentPage}
      grouppedTickets={data?.results}
      onChangePagination={(newPage) => setCurrentPage(newPage)}
      totalTickets={data?.count}
      isStarted={isStarted}
      isLoading={isLoading}
      onClose={onClose}
      open={openTableModal}
      ticketName='tournamentTicket'
      title={`resultados - ${tournamentName}`.toLocaleUpperCase()}
    />
  );
};

export default TournamentTicketsTable;
