import React, { useEffect, useMemo, useState } from 'react';
import PredictionResult, {
  MatchesWithPredictions,
} from '../predictionResult/PredictionResult';
import {
  useGetFixture,
  useGetRound,
  useGetSavePredictionSecret,
} from '../../../../serverStore/queries';
import useClientStore from '../../../../clientStore/useClientStore';
import { useSearchUserPrediction } from '../../../../serverStore/mutations';
import { fetchPredictions } from '../../../../../utils';

interface IFixturePredictionResultProps {
  email: string;
  tournamentId?: number;
  fixtureId?: number;
  roundId?: number;
}

const FixturePredictionResult: React.FC<IFixturePredictionResultProps> = ({
  email,
  fixtureId,
  roundId,
  tournamentId,
}) => {
  const { loggedUser, fixtureSelected } = useClientStore();
  const [roundIdState, setRoundIdState] = useState<number | undefined>(
    undefined,
  );

  const selectedFixtureId = fixtureId || fixtureSelected.fixtureId;
  const selectedTournamentId = tournamentId || fixtureSelected.tournamentId;
  const selectedRoundId = roundId || roundIdState || fixtureSelected.roundId;

  const { data: secretSavePredictions, isLoading: loadingGetSecret } =
    useGetSavePredictionSecret();

  const {
    data: fixtureData,
    isLoading: loadingGetFixtures,
    refetch: refetchGetFixture,
  } = useGetFixture({
    tournamentId: selectedTournamentId,
    fixtureId: selectedFixtureId,
    enabledRequest:
      !!selectedFixtureId && !!loggedUser && !!selectedTournamentId,
  });
  const {
    data: round,
    isLoading: loadingGetRounds,
    refetch: refetchRound,
  } = useGetRound({
    fixtureId: selectedFixtureId,
    roundId: selectedRoundId,
    enabledRequest: !!selectedFixtureId && !!loggedUser && !!selectedRoundId,
  });

  const {
    data: responsePredictions,
    mutate: searchMutate,
    isPending: loadingGetPredictions,
  } = useSearchUserPrediction();

  const isLoading =
    loadingGetFixtures ||
    loadingGetRounds ||
    loadingGetSecret ||
    loadingGetPredictions;

  const handleChangeRound = (value: string) => {
    setRoundIdState(Number(value));
  };

  const matchesWhitPredictions: MatchesWithPredictions[] | undefined =
    useMemo(() => {
      return round?.matches?.map((m) => {
        const predictionMatch = responsePredictions?.predictions?.find(
          (p) => p.matchId === m.id,
        );
        return {
          ...m,
          prediction: predictionMatch,
        };
      });
    }, [round, responsePredictions]);

  //maneja los cambios de ronda y predicciones de la ronda
  useEffect(() => {
    //Si existe algun round id y no hay data de la ronda todavia hacer el get
    if (!round && selectedRoundId) {
      refetchRound();
    }

    //si existe round data pero la ronda id seleccionada es distinta volvera hacer el get
    if (selectedRoundId && selectedRoundId !== round?.id) {
      refetchRound();
    }

    //Si existe round id y no busco predicciones o las predicciones corresponden a otro round id volvera hacer el get
    if (
      round?.id &&
      (!responsePredictions || responsePredictions?.roundId !== round?.id) &&
      secretSavePredictions &&
      email
    ) {
      fetchPredictions({
        email,
        roundId: round.id,
        secretSavePredictions,
        searchMutate,
        includeMatch: !!email,
      });
    }
  }, [
    selectedRoundId,
    round?.id,
    secretSavePredictions,
    email,
    responsePredictions,
  ]);

  //Maneja los cambios de fixture o torneo
  useEffect(() => {
    if (
      selectedFixtureId &&
      selectedTournamentId &&
      (!fixtureData ||
        fixtureData?.id !== selectedFixtureId ||
        fixtureData?.tournament?.id !== selectedTournamentId)
    ) {
      refetchGetFixture();
    }
  }, [selectedFixtureId, selectedTournamentId]);

  return (
    <PredictionResult
      fixtureName={fixtureData?.name}
      matchesWhitPredictions={matchesWhitPredictions}
      tournamentName={fixtureData?.tournament?.name}
      isLoading={isLoading}
      rounds={fixtureData?.rounds}
      onChangeRound={handleChangeRound}
      roundId={selectedRoundId}
    />
  );
};

export default FixturePredictionResult;
