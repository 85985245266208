import { UseMutationResult, useMutation } from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import { ResponsePredictions } from '../../../../types/prediction.types';

import useClientStore from '../../../clientStore/useClientStore';
import encryptData from '../../../../utils/hooks/encryptData';
import { decryptData } from '../../../../utils';

export type DataSearchPredictions = {
  roundId: number;
  savePredictionsSecret: string;
  email?: string;
  includeMatch?: boolean;
  includeTeam?: boolean;
};

export type ParamsSearchPredictions = {
  data: DataSearchPredictions;
  x: string;
};

type IPostSavePredictions = ({
  data,
  x,
}: ParamsSearchPredictions) => Promise<ResponsePredictions>;
const postSearchUserPrediction: IPostSavePredictions = async ({ data, x }) => {
  const encryptedPredictions = await encryptData(data, x);
  const response: string = await axiosFetch('/prediction/search', {
    method: 'POST',
    data: { encryptedPredictions },
  });

  const decrypPredictionstData = await decryptData(response);
  return decrypPredictionstData;
};

const useSearchUserPrediction: () => UseMutationResult<
  ResponsePredictions,
  any,
  ParamsSearchPredictions,
  unknown
> = () => {
  const { dispatch } = useClientStore();

  return useMutation({
    mutationFn: postSearchUserPrediction,

    onError: () => {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: 'Error',
          description:
            'Ocurrio un error al buscar las predicciones. Por favor intenta de nuevo mas tarde',
          status: 'error',
        },
      });
    },
  });
};

export default useSearchUserPrediction;
