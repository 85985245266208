import React, { useState } from 'react';
import styles from './tag.module.css';
interface ITagProps {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
}

const Tag: React.FC<ITagProps> = ({ label, checked, onChange, onClick }) => {
  const [check, setCheck] = useState(false);

  const isChecked = checked || check;

  const onTagClick = () => {
    onClick?.();
    if (checked != null) {
      return onChange?.(!checked);
    }
    setCheck(!check);
    return onChange?.(!check);
  };

  return (
    <div
      className={`${styles.tag} ${isChecked ? styles.checked : ''}`}
      onClick={onTagClick}
    >
      {label}
    </div>
  );
};

export default Tag;
