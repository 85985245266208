import CryptoJS from 'crypto-js';
import generateXAK from './generateXAK';

const encryptData = async (datos: any, x: string) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(datos),
    await generateXAK({ str2: x }),
  ).toString();
};

export default encryptData;
