import React from 'react';
import { capitalizeFirstLetter } from '../../../../../utils';
import { Column, Flex } from '../../../../../utils/layout';
import styles from './predictionResult.module.css';
import { SpinerScreen } from '../../../../commons';
import { PredictionAttributes } from '../../../../../types/prediction.types';
import PredictionResultHeader from './components/predictionResultHeader/PredictionResultHeader';
import PredictionResultItem from './components/predictionResultItem/PredictionResultItem';
import { MatchAttributes } from '../../../../../types/match.types';
import { Select } from 'antd';
import { RoundAttributes } from '../../../../../types/round.types';

export type MatchesWithPredictions = MatchAttributes & {
  prediction?: PredictionAttributes;
};

interface ITicketComponentProps {
  matchesWhitPredictions?: MatchesWithPredictions[];
  isLoading?: boolean;
  tournamentName?: string;
  fixtureName?: string;
  rounds?: RoundAttributes[]; //agregar select rounbd
  roundId?: number;
  onChangeRound: (value: string) => void;
}

const PredictionResult: React.FC<ITicketComponentProps> = ({
  isLoading,
  fixtureName,
  tournamentName,
  matchesWhitPredictions,
  rounds,
  roundId,
  onChangeRound,
}) => {
  const roundOptions = rounds?.map((round) => ({
    value: String(round.id),
    label: capitalizeFirstLetter(round.name),
  }));

  const totalPoints = matchesWhitPredictions?.reduce(
    (acc, curr) => acc + (curr?.prediction?.resultPoints || 0),
    0,
  );
  if (isLoading || !matchesWhitPredictions || !fixtureName || !tournamentName) {
    return <SpinerScreen className={styles.spiner} />;
  }

  return (
    <Column>
      {/* TABLE */}
      <div className={styles.table}>
        <Column className={styles.tableHeader}>
          {/* HEADER */}
          <Column className={styles.titleHeaderText}>
            <Flex>
              <p>{capitalizeFirstLetter(tournamentName)}</p>
            </Flex>

            <Flex className={styles.selectRound}>
              {roundOptions && roundOptions?.length > 1 ? (
                <>
                  <p>Ronda - </p>
                  <Select
                    defaultValue={String(roundId)}
                    style={{ width: 'auto' }}
                    onChange={onChangeRound}
                    options={roundOptions}
                  />
                </>
              ) : (
                <p>{capitalizeFirstLetter(fixtureName)}</p>
              )}
            </Flex>
          </Column>

          <div className={styles.dividerHeader} />
          <PredictionResultHeader />
        </Column>
        <div className={styles.positionRelative}>
          <Column className={styles.tableContent}>
            {matchesWhitPredictions?.length
              ? matchesWhitPredictions.map((p, i) => (
                  <div key={`predTic-${i}`}>
                    <PredictionResultItem
                      matchWhitPrediction={p}
                      key={`tc-${i}`}
                    />
                  </div>
                ))
              : null}
            {/* TOTAL RESULTADO PUNTOS */}
          </Column>
          {totalPoints ? (
            <Flex className={styles.totalPoints}>+{totalPoints}</Flex>
          ) : null}
        </div>
        {/* INFORMACION */}
        <Flex className={styles.containerInfo}>
          <Flex className={styles.infoItem}>
            <div className={styles.boxExact} />
            <p>Exacto suma 2 puntos.</p>
          </Flex>
          <Flex className={styles.infoItem}>
            <div className={styles.box} />
            <p>Acierto suma 1 punto.</p>
          </Flex>
        </Flex>
      </div>
    </Column>
  );
};

export default PredictionResult;
